import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/usr/src/app/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "getting-help-with-planday-api"
    }}>{`Getting help with Planday API`}</h1>
    <p>{`We appreciate that you are looking to integrate to Planday using our APIs.`}</p>
    <p>{`You can get in touch with us by sending an email to `}<a parentName="p" {...{
        "href": "mailto:apisupport@planday.com"
      }}>{`apisupport@planday.com`}</a>{`.`}</p>
    <p>{`Please use the following template when sharing your questions with us`}</p>
    <pre><code parentName="pre" {...{}}>{`Subject: {question topic}


App ID: {provide APP ID of your test application if you've got one already}

Portal URL: {provide the URL of your portal e.g. devdemo.planday.com}

API request URL: {provide URL of your request if relevant}
API request body: {provide if relevant}

API response code: {provide if relevant}
API response body: {provide if relevant}

DateTime of API request: {provide date and times of your requests if relevant to the case}

Question/Issue details: {describe your question or issue in detail. Include actual vs. expected behaviour if relevant.}

`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      